var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-drawer",
    {
      attrs: {
        width: "35%",
        "label-col": 4,
        "wrapper-col": 14,
        visible: _vm.open,
        title: _vm.fromTitle,
      },
      on: { close: _vm.onClose },
    },
    [
      _c(
        "a-form-model",
        { ref: "form", attrs: { model: _vm.form, rules: _vm.rules } },
        [
          _c(
            "a-form-model-item",
            { attrs: { label: "消息类型ID", prop: "typeId" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入消息类型ID" },
                model: {
                  value: _vm.form.typeId,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "typeId", $$v)
                  },
                  expression: "form.typeId",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "用户组ID", prop: "groupId" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入用户组ID" },
                model: {
                  value: _vm.form.groupId,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "groupId", $$v)
                  },
                  expression: "form.groupId",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "消息应用Id", prop: "appId" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入消息应用Id" },
                model: {
                  value: _vm.form.appId,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "appId", $$v)
                  },
                  expression: "form.appId",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "消息模板ID", prop: "templateId" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入消息模板ID" },
                model: {
                  value: _vm.form.templateId,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "templateId", $$v)
                  },
                  expression: "form.templateId",
                },
              }),
            ],
            1
          ),
          _vm.formType === 1
            ? _c(
                "a-form-model-item",
                { attrs: { label: "删除标志", prop: "delFlag" } },
                [
                  _c("a-input", {
                    attrs: { placeholder: "请输入删除标志" },
                    model: {
                      value: _vm.form.delFlag,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "delFlag", $$v)
                      },
                      expression: "form.delFlag",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            { staticClass: "bottom-control" },
            [
              _c(
                "a-space",
                [
                  _c(
                    "a-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.submitForm },
                    },
                    [_vm._v(" 保存 ")]
                  ),
                  _c(
                    "a-button",
                    { attrs: { type: "dashed" }, on: { click: _vm.cancel } },
                    [_vm._v(" 取消 ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }