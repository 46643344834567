import request from '@/utils/request'

// 查询用户组消息应用配置列表
export function listTypeChannelConfig (query) {
  return request({
    url: '/message/typeChannelConfig/list',
    method: 'get',
    params: query
  })
}

// 查询用户组消息应用配置详细
export function getTypeChannelConfig (id) {
  return request({
    url: '/message/typeChannelConfig/' + id,
    method: 'get'
  })
}

// 新增用户组消息应用配置
export function addTypeChannelConfig (data) {
  return request({
    url: '/message/typeChannelConfig',
    method: 'post',
    data: data
  })
}

// 修改用户组消息应用配置
export function updateTypeChannelConfig (data) {
  return request({
    url: '/message/typeChannelConfig',
    method: 'put',
    data: data
  })
}

// 删除用户组消息应用配置
export function delTypeChannelConfig (id) {
  return request({
    url: '/message/typeChannelConfig/' + id,
    method: 'delete'
  })
}

// 导出用户组消息应用配置
export function exportTypeChannelConfig (query) {
  return request({
    url: '/message/typeChannelConfig/export',
    method: 'get',
    params: query
  })
}
