<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" :title="fromTitle" @close="onClose">
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="消息类型ID" prop="typeId" >
        <a-input v-model="form.typeId" placeholder="请输入消息类型ID" />
      </a-form-model-item>
      <a-form-model-item label="用户组ID" prop="groupId" >
        <a-input v-model="form.groupId" placeholder="请输入用户组ID" />
      </a-form-model-item>
      <a-form-model-item label="消息应用Id" prop="appId" >
        <a-input v-model="form.appId" placeholder="请输入消息应用Id" />
      </a-form-model-item>
      <a-form-model-item label="消息模板ID" prop="templateId" >
        <a-input v-model="form.templateId" placeholder="请输入消息模板ID" />
      </a-form-model-item>
      <a-form-model-item label="删除标志" prop="delFlag" v-if="formType === 1">
        <a-input v-model="form.delFlag" placeholder="请输入删除标志" />
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getTypeChannelConfig, addTypeChannelConfig, updateTypeChannelConfig } from '@/api/message/typeChannelConfig'

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
  },
  data () {
    return {
      loading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,
        typeId: null,
        groupId: null,
        appId: null,
        templateId: null
      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        typeId: [
          { required: true, message: '消息类型ID不能为空', trigger: 'blur' }
        ],
        groupId: [
          { required: true, message: '用户组ID不能为空', trigger: 'blur' }
        ],
        appId: [
          { required: true, message: '消息应用Id不能为空', trigger: 'blur' }
        ],
        templateId: [
          { required: true, message: '消息模板ID不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        typeId: null,
        groupId: null,
        appId: null,
        templateId: null
      }
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getTypeChannelConfig(id).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          if (this.form.id !== undefined && this.form.id !== null) {
            updateTypeChannelConfig(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            })
          } else {
            addTypeChannelConfig(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
